import * as Sentry from "@sentry/browser";
import {
  arrow,
  autoUpdate,
  computePosition,
  flip,
  offset,
  shift,
} from "@floating-ui/dom";
import { setDateRangePickerChanged } from "../state.js";

export const urlParams = new URLSearchParams(window.location.search);

const LOCALE = new Intl.NumberFormat().resolvedOptions().locale;

const CACHE = {};

export const chartColors = [
  "#049BD7",
  "#e60049",
  "#50e991",
  "#e6d800",
  "#9b19f5",
  "#ffa300",
  "#dc0ab4",
  "#b3d4ff",
  "#00bfa0",
];

export const LOGO_MAPPING = {
  "angel.co": "fab fa-angellist",
  "artstation.com": "fab fa-artstation",
  "behance.net": "fab fa-behance-square",
  "blogger.com": "fab fa-blogger",
  "dev.to": "fab fa-dev",
  "deviantart.com": "fab fa-deviantart",
  "dribbble.com": "fab fa-dribbble",
  "facebook.com": "fab fa-facebook-f",
  "flickr.com": "fab fa-flickr",
  "foursquare.com": "fab fa-foursquare",
  "github.com": "fab fa-github-alt",
  "instagram.com": "fab fa-instagram",
  "kaggle.com": "fab fa-kaggle",
  "linkedin.com/in/": "fab fa-linkedin-in",
  "linkedin.com/sales/": "fab fa-linkedin",
  "linkedin.com/recruiter/": "fab fa-linkedin",
  "linkedin.com/talent/": "fab fa-linkedin",
  "medium.com": "fab fa-medium",
  "meetup.com": "fab fa-meetup",
  "pinterest.com": "fab fa-pinterest-p",
  "quora.com": "fab fa-quora",
  "reddit.com": "fab fa-reddit-alien",
  "researchgate.net": "fab fa-researchgate",
  "soundcloud.com": "fab fa-soundcloud",
  "stackexchange.com": "fab fa-stack-exchange",
  "stackoverflow.com": "fab fa-stack-overflow",
  "tumblr.com": "fab fa-tumblr",
  "twitter.com": "fab fa-twitter",
  "vk.com": "fab fa-vk",
  "vimeo.com": "fab fa-vimeo-v",
  "wordpress.com": "fab fa-wordpress-simple",
  "xing.com": "fab fa-xing",
  "youtube.com": "fab fa-youtube",
};

export const CRMS = {
  airtable: "Airtable",
  applicantstack: "ApplicantStack",
  ashby: "Ashby",
  asymbl: "Asymbl",
  avionte: "Avionté",
  bigbiller: "Top Echelon",
  bluesky: "BlueSky",
  bullhorn: "Bullhorn",
  breezy: "Breezy HR",
  cats: "CATS",
  chameleoni: "Chameleon-i",
  clickup: "ClickUp",
  clockwork: "Clockwork Recruiting",
  colleague: "Colleague",
  comeet: "Comeet",
  copper: "Copper",
  crelate: "Crelate",
  dynamics: "Dynamics",
  eboss: "eBoss",
  encore: "Encore",
  exelare: "Exelare",
  ezekia: "Ezekia",
  firefish: "Firefish",
  googlecontacts: "Google Contacts",
  greenhouse: "Greenhouse",
  highlevel: "GoHighLevel",
  hiregenius: "Hire Genius",
  homerun: "Homerun",
  hubspot: "HubSpot",
  invenias: "Invenias",
  itris: "itris",
  jazz: "JazzHR",
  jobadder: "JobAdder",
  jobdiva: "JobDiva",
  jobvite: "Jobvite",
  kortivity: "Kortivity",
  lessannoying: "Less Annoying CRM",
  lever: "Lever",
  loxo: "Loxo",
  manatal: "Manatal",
  mercury: "Mercury xRM",
  pcrecruiter: "PCRecruiter",
  peopleforce: "PeopleForce",
  personio: "Personio",
  pinpoint: "Pinpoint",
  pipedrive: "Pipedrive",
  podio: "Podio",
  pump: "Pump",
  recruitcrm: "Recruit CRM",
  recruitee: "Recruitee",
  recruiterflow: "Recruiterflow",
  recruitly: "Recruitly",
  recruitmentboost: "Recruitment Boost",
  s3: "S3",
  salesforce: "Salesforce",
  seven20: "Seven20",
  smartrecruiters: "SmartRecruiters",
  successfactors: "SuccessFactors",
  streak: "Streak",
  talentrover: "Bullhorn for Salesforce",
  taleo: "Taleo",
  targetrecruit: "TargetRecruit",
  teamtailor: "Teamtailor",
  thrive: "Thrive",
  tracker: "Tracker",
  vincere: "Vincere",
  voyager: "Voyager Infinity",
  workable: "Workable",
  zohocrm: "Zoho CRM",
  "zoho-recruit": "Zoho Recruit",
};

export const LOGO_MAPPING_COLORS = {
  "linkedin.com/sales": "gold",
  "linkedin.com/recruiter": "gold",
  "linkedin.com/talent": "gold",
};

export const uuidV4 = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const titleCase = (string) => {
  const formattedString = string.charAt(0).toUpperCase() + string.slice(1);
  return formattedString.replace(/([A-Z])/g, " $1").trim();
};

export const nameAndPhoto = (name, photo, classList = "", link = null) => {
  let text = `<span class='d-inline-flex align-items-center'>${
    name || ""
  }</span>`;

  if (link) text = `<a href=${link} target="_blank">${text}</a>`;

  return `
      <div class="name-and-photo candidate-profile-link d-flex align-items-center my-1 user-select-none ${classList}">
        <img src=${
          photo || "/assets/img/icons/user.png"
        } class="rounded-circle photo-image mr-2" style="width: 1.5rem; height: 1.5rem">
        ${text}
      </div>`;
};

export const wrapTooltip = (
  content,
  tooltip,
  position = "bottom",
  wrapperClasses = "",
) => {
  const wrapperId = `tooltip-wrapper-${uuidV4()}`;
  const tooltipId = `tooltip-${uuidV4()}`;
  const arrowId = `${tooltipId}-arrow`;

  const htmlContent = `
    <div id="${wrapperId}" class="sw-tt-wrap ${wrapperClasses}">
      ${content}
      <div id="${tooltipId}" class="sw-tt-text d-none" role="tooltip">
        ${tooltip}
        <div id="${arrowId}" class="sw-tt-arrow"></div>
      </div>
    </div>
  `;

  const setupTooltip = () => {
    const wrapper = document.getElementById(wrapperId);
    const tooltipElement = document.getElementById(tooltipId);
    const arrowElement = document.getElementById(arrowId);

    if (!wrapper || !tooltipElement || !arrowElement) return;

    let cleanup;

    const update = () => {
      computePosition(wrapper, tooltipElement, {
        placement: position,
        middleware: [
          offset(8),
          flip(),
          shift({ padding: 5 }),
          arrow({ element: arrowElement }),
        ],
      }).then(({ x, y, placement, middlewareData }) => {
        Object.assign(tooltipElement.style, {
          left: `${x}px`,
          top: `${y}px`,
        });

        const { x: arrowX, y: arrowY } = middlewareData.arrow;

        const staticSide = {
          top: "bottom",
          right: "left",
          bottom: "top",
          left: "right",
        }[placement.split("-")[0]];

        Object.assign(arrowElement.style, {
          left: arrowX != null ? `${arrowX}px` : "",
          top: arrowY != null ? `${arrowY}px` : "",
          right: "",
          bottom: "",
          [staticSide]: "-4px",
        });

        tooltipElement.classList.remove("d-none");
        tooltipElement.classList.add("d-block");
      });
    };

    $(wrapper).on("mouseenter", () => {
      update();
      tooltipElement.style.opacity = "1";
      tooltipElement.style.visibility = "visible";
      cleanup = autoUpdate(wrapper, tooltipElement, update);
    });

    $(wrapper).on("mouseleave", () => {
      tooltipElement.style.opacity = "0";
      tooltipElement.style.visibility = "hidden";
      tooltipElement.classList.remove("d-block");
      tooltipElement.classList.add("d-none");

      if (cleanup) {
        cleanup();
        cleanup = null;
      }
    });
  };

  requestAnimationFrame(setupTooltip);

  return htmlContent;
};

export const photoAndTooltip = (user, size = "1.25rem") => {
  if (!user) return "";
  const email = user.email || "";
  return wrapTooltip(
    `<img
        src=${user.picture || user.photo || "/assets/img/icons/user.png"}
        class="rounded-circle"
        style="height: ${size}; width: ${size};""
        >`,
    `${user.name || "Unknown User"}<br>${email}`,
  );
};

export const yesNoConfirm = (
  message,
  yesCallback,
  noCallback = null,
  yesLabel = "Yes",
  noLabel = "No",
) =>
  bootbox.confirm({
    message,
    buttons: {
      cancel: {
        label: noLabel,
        className: "btn-light",
      },
      confirm: {
        label: yesLabel,
        className: "btn-primary ml-2",
      },
    },
    callback: (result) => {
      if (result) yesCallback();
      else if (noCallback) noCallback();
    },
  });

export const backdropAlert = (message, size = null) =>
  bootbox.alert({ message, size });

export const messageAlert = (message, title = null) =>
  bootbox.dialog({ title, message });

export const yesNoConfirmExtended = ({
  msgData,
  yesCallback,
  noCallback = null,
}) => {
  const { action, selectedCount, targetType, type, providerTitle } = msgData;

  if (selectedCount === 0) {
    backdropAlert("Please make a selection");
    return;
  }

  if (selectedCount === 1 && action === "Delete") {
    if (type === "campaign") {
      yesNoConfirm(
        `Delete 1 campaign? This campaign cannot be retrieved after deletion. Contacts will not be deleted but outreach will stop.`,
        () => yesCallback(),
        null,
        "Delete",
        "Cancel",
      );
    } else {
      yesNoConfirm(
        `Are you sure you would like to delete this ${targetType}?`,
        () => {
          yesCallback();
        },
      );
    }
    return;
  }

  if (selectedCount === 1) {
    yesCallback();
    return;
  }

  let title;
  let message;
  let className = "";

  if (type === "campaign") {
    title = "Delete Campaigns";
    message = `<p>You are going to delete <strong>${selectedCount}</strong> campaigns, these campaigns cannot be retrieved after deletion.</p><p style="font-weight:bold;">To continue with this action, please type below the number of campaigns to delete.</p>`;
  }

  if (type === "send") {
    title = "Send emails";
    message = `<p>You are going to ${action} emails to <strong>${selectedCount}</strong> contacts.</p><p style="font-weight:bold;">To continue with this action, please type below the number of contacts that will be changed.</p>`;
  }

  const isDeleted = action === "deleted";

  if (type === "status") {
    title = `${isDeleted ? `Delete tasks` : `Change status`}`;
    message = `${
      isDeleted
        ? `<p>You are going to delete <strong>${selectedCount}</strong> tasks.</p><p style="font-weight:bold;">To continue with this action, please type below the number of contacts that will be changed.</p>`
        : `<p>You are going to change the status of <strong>${selectedCount}</strong> tasks to ${action}.</p><p style="font-weight:bold;">To continue with this action, please type below the number of contacts that will be changed.</p>`
    } `;
  }

  if (type === "stage") {
    title = "Change stage";
    message = `<p>You are going to change <strong>${selectedCount}</strong> contacts' stage to ${action}. </p><p style="font-weight:bold;">To continue with this action, please type below the number of contacts that will be changed.</p>`;
  }

  if (type === "enrich") {
    let msg;
    if (["email", "both"].includes(action)) {
      msg = `<p>You are going to enrich the emails of <strong>${selectedCount}</strong> contacts</p>`;
    } else if (action === "phone") {
      msg = `<p>You are going to enrich the phones of <strong>${selectedCount}</strong> contacts</p>`;
    } else if (providerTitle) {
      msg = `<p>You are going to enrich <strong>${selectedCount}</strong> contacts using ${providerTitle}</p>`;
    } else {
      msg = `<p>You are going to enrich the ${action} emails of <strong>${selectedCount}</strong> contacts</p>`;
    }

    title = "Enrich contacts";
    message = `${msg} <p style="font-weight:bold;">To continue with this action, please type below the number of contacts that will be enriched.</p>`;
    className = "enrichModal";
  }

  if (type === "modify") {
    title = `${action} ${targetType}s`;
    message = `<p>You are going to ${action.toLowerCase()} <strong>${selectedCount}</strong> ${targetType}s. </p><p style="font-weight:bold;">To continue with this action, please type below the number of ${targetType}s to ${action.toLowerCase()}.</p>`;
  }

  bootbox
    .prompt({
      title,
      message,
      className,
      inputType: "text",
      pattern: selectedCount,
      placeholder: selectedCount,
      required: true,
      buttons: {
        cancel: { label: "Cancel", className: "btn-light" },
        confirm: { label: "Confirm", className: "btn-primary ml-2" },
      },
      callback: (result) => {
        if (result) yesCallback();
        else if (noCallback) noCallback();
      },
    })
    .on("shown.bs.modal", (e) => {
      const $target = $(e.currentTarget);

      const input = $target.find(".bootbox-input-text");
      const confirmBtn = $target.find(".bootbox-accept");
      const closeBtn = $target.find(".bootbox-close-button");

      const modalHeader = $target.find(".modal-header");
      const modalTitle = $target.find(".modal-title");

      const bgColor = $target.hasClass("enrichModal") ? "#1da2d8" : "#e74a3b";

      modalHeader.css("background", bgColor);
      confirmBtn.css("background", bgColor);

      modalTitle.addClass("important-color-white");
      closeBtn.addClass("important-color-white");
      confirmBtn.addClass("important-color-white disabled");

      input.after(
        '<p id="error-message" style="display:none;margin-top:10px;margin-bottom:0;color:#e74a3b">You have entered an incorrect number of contacts.</p>',
      );

      $(input).on("input", (ev) => {
        const match = ev.target.value === selectedCount.toString();
        const error = ev.target.value.length > 0 && !match;
        confirmBtn.toggleClass("disabled", !match);
        $("#error-message").toggle(error);
        input.css("border", error ? "2px solid #e74a3b" : "1px solid #e4e6ec");
      });
    });
};

export const caseInsensitiveSort = (arr, key = null) => {
  const newArr = Array.from(arr);
  if (key)
    return newArr.sort((a, b) =>
      a[key].toLowerCase().localeCompare(b[key].toLowerCase()),
    );
  return newArr.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
};

export const showToast = (opts = {}) => {
  const uniqueId = opts.id || uuidV4(); // Manually set an ID in opts if you wish to prevent a specific toast from stacking
  const toastId = `toast-${uniqueId}`;

  const toastExists = $(`#${toastId}`).length;

  if (toastExists) {
    return;
  }

  const {
    title, // required
    subtitle = "",
    type = "info", // info, success, warning, danger
    timeout = 4000,
    links = {},
  } = opts;

  const toastHtml = /* html */ `
        <div id="${toastId}" class="toast toast-${type}" style="display: none;">
          <div class="toast-icon-container"></div>
          <div class="toast-text-container">
            <span class="toast-text-container-title">${title}</span>
            <span class="toast-text-container-subtitle">${subtitle}</span>
          </div>
          <div class="toast-close">
            <i class="fa-regular fa-xmark"></i>
          </div>
        </div>`;

  const $toastNotifications = $("#toast-notifications");

  $toastNotifications.append(toastHtml);

  Object.entries(links).forEach(([kind, link]) => {
    const subtitleElement = $(`#${toastId} .toast-text-container-subtitle`);

    subtitleElement.append(
      `<br /><a href="${link}" target="_blank">${kind}</a>`,
    );
  });

  const $toast = $(`#${toastId}`);

  $toast.fadeIn(400);

  let toastTimeout;

  const removeToast = () => {
    // eslint-disable-next-line func-names
    $toast.fadeOut(400, function () {
      $(this).remove();
      if ($toastNotifications.children().length === 0) {
        $toastNotifications.empty();
      }
    });
  };

  const startTimeout = () => {
    toastTimeout = setTimeout(() => removeToast(), timeout);
  };

  startTimeout();

  $toast.find(".toast-close").click(() => removeToast());

  $("#toast-notifications").hover(
    () => clearTimeout(toastTimeout),
    startTimeout,
  );
};

// hide alerts instead of remove, when dismissed

export const showSlowProviderResponseMessage = () => {
  const mapping = {
    azure: "Outlook",
    exchange: "Exchange",
    gcp: "Gmail",
  };
  const provider = localStorage.getItem("provider");
  const providerTitle = mapping[provider] || "Your email provider";
  showToast({
    title: `${providerTitle} is taking a long time`,
    subtitle: "Please try again later or contact support if the issue persists",
    type: "warning",
  });
};

export const isValidEmail = (email) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email,
  );

export const saveUser = (user) => {
  const copy = { ...user };
  delete copy.signature;
  localStorage.setItem("user", JSON.stringify(copy));
};

export const getUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return {};
};

export const getTimezone = () => {
  CACHE.tz =
    CACHE.tz ||
    getUser().timezone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  return CACHE.tz;
};

const localeString = (d, opts) => {
  const cacheKey = `dateTimeFormatter:${JSON.stringify(opts)}`;
  CACHE[cacheKey] = CACHE[cacheKey] || new Intl.DateTimeFormat(LOCALE, opts);
  return CACHE[cacheKey].format(d);
};

const localTimeComponent = (timestamp, key, convertToInt = false) => {
  const d = new Date(timestamp * 1000);
  const opts = { timeZone: getTimezone(), hour12: false };
  opts[key] = key === "year" ? "numeric" : "2-digit";
  let ret = localeString(d, opts);
  if (ret.length === 1) ret = "0".concat(ret);
  return convertToInt ? parseInt(ret, 10) : ret;
};

export const localDateTimeString = (
  timestamp,
  weekday = true,
  timezone = null,
) => {
  const d = new Date(timestamp * 1000);
  const opts = {
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: timezone || getTimezone(),
  };
  if (weekday) opts.weekday = "short";
  if (d.getFullYear() !== new Date().getFullYear()) {
    opts.year = "numeric";
  }
  return localeString(d, opts);
};

export const localDateString = (timestamp, weekday = true, year = true) => {
  const d = new Date(timestamp * 1000);
  const opts = {
    month: "short",
    day: "numeric",
    timeZone: getTimezone(),
  };
  if (weekday) opts.weekday = "short";
  if (year) opts.year = "numeric";
  return localeString(d, opts);
};

export const humanFriendlyTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const delta = Math.round((+new Date() - date) / 1000);

  const minute = 60;
  const hour = minute * 60;

  if (!timestamp) return "";

  if (delta < minute) return `${delta}s ago`;

  if (delta < hour) return `${Math.floor(delta / minute)}min ago`;

  return localDateTimeString(timestamp, false);
};

export const numericDateString = (timestamp) => {
  const year = localTimeComponent(timestamp, "year");
  const month = localTimeComponent(timestamp, "month");
  const day = localTimeComponent(timestamp, "day");
  return `${year}-${month}-${day}`;
};

export const dateForDateTimeInputValue = (timestamp) => {
  const year = localTimeComponent(timestamp, "year");
  const month = localTimeComponent(timestamp, "month");
  const day = localTimeComponent(timestamp, "day");
  const hour = localTimeComponent(timestamp, "hour");
  const minute = localTimeComponent(timestamp, "minute");
  return `${year}-${month}-${day}T${hour}:${minute}`;
};

export const cookieValue = (key) =>
  (new RegExp(`${key || "="}=(.*?); `, "gm").exec(`${document.cookie}; `) || [
    "",
    null,
  ])[1];

export const removeSpinner = (selector) => {
  $(selector).prop("disabled", false);
  selector.split(",").map((s) => $(`${s} .sw-spinner`).remove());
};

export const addSpinner = (selector, timeout) => {
  removeSpinner(selector); // ensure no other spinners are present

  $(selector).append(
    '<i class="ml-2 fa-solid fa-circle-notch fa-spin fast-spin sw-spinner"></i>',
  );
  $(selector).prop("disabled", true);

  if (timeout) {
    setTimeout(() => {
      removeSpinner(selector);
    }, timeout);
  }
};

export const isUrl = (text) => {
  const expr =
    /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?$/gi;
  return !!text.match(new RegExp(expr));
};

export const regularizedPhone = (text) => {
  const ret = text.replace(/[^\d]/g, "");
  const n = Math.max(ret.length - 10, 0);
  return ret.slice(n);
};

export const toPercent = (num, den, cap = 100, limit = 20) => {
  if (!den) return "-";
  num = Math.min(num, den);
  let ret = (num / den) * 100;
  if (num > limit && ret > cap) ret = cap - (num % 4);
  return `${Math.ceil(ret)}%`;
};

const getDate = (selector, dateType) => {
  const { startDate, endDate } = $(`${selector}`).data("daterangepicker");
  const date = dateType === "start" ? startDate : endDate;
  return moment(date).local().format("YYYY-MM-DD");
};

export const getStartDate = (selector) => getDate(selector, "start");

export const getEndDate = (selector) => getDate(selector, "end");

export const handleToggleFilter = ({
  items,
  ids,
  toggleSelector,
  tableId,
  fillTable,
  table,
  property,
}) => {
  let filteredItems = [];
  const toggle = $(toggleSelector).val();

  switch (toggle) {
    case "OR":
      filteredItems = items.filter((item) =>
        item[property]?.some((id) => ids.includes(id)),
      );
      break;
    case "AND":
      filteredItems = items.filter((item) =>
        ids.every((id) => item[property]?.includes(id)),
      );
      break;
    case "NOT":
      filteredItems = items.filter((item) =>
        item[property]?.length
          ? !item[property].some((id) => ids.includes(id))
          : false,
      );
      break;
    default:
  }

  if (ids.length === 0) filteredItems = items;

  if (tableId && fillTable) fillTable(tableId, filteredItems);
  else if (table) {
    table.clear();
    table.rows.add(filteredItems).draw();
  }
};

export const addSearchToggleClickEvent = ({
  drawTable = false,
  callback = null,
  includeNot = true,
}) => {
  $(document)
    .off("click", ".boolean-search-toggle")
    .on("click", ".boolean-search-toggle", (e) => {
      const el = $(e.currentTarget);

      const toggleType = el.hasClass("comparison-toggle")
        ? "comparison"
        : "boolean";

      const hiddenSelectpicker = el
        .parents(".boolean-selector")
        .find(".hidden-selectpicker");

      const currentValue = hiddenSelectpicker.val();
      let newValue;

      if (toggleType === "comparison")
        newValue = currentValue === ">" ? "<" : ">";
      else if (includeNot)
        switch (currentValue) {
          case "OR":
            newValue = "AND";
            break;
          case "AND":
            newValue = "NOT";
            break;
          case "NOT":
            newValue = "OR";
            break;
          default:
        }
      else newValue = currentValue === "OR" ? "AND" : "OR";

      hiddenSelectpicker.val(newValue);

      if (callback) callback();

      el.find(".option").hide();
      el.find(`.option[data-value="${newValue}"]`).show();

      if (drawTable) $(`#${drawTable}`).DataTable().draw();
    });
};

export const trainingTasks = {
  start: [
    {
      id: "download-chrome-extension",
      title: "Download Chrome extension",
      videoSrc:
        "https://www.sourcewhale.app/training-videos/Quick Start Guide/Downloading the extension.mp4",
      modalButton: {
        title: "Download",
        link: "https://chrome.google.com/webstore/detail/sourcewhale/hfnnombjdnokggcepagcndckmngedifk",
      },
      estimatedTime: 2,
      icon: "fa-cloud-arrow-down",
    },
    {
      id: "set-up-signature",
      title: "Set up signature",
      url: "/profile",
      estimatedTime: 2,
      icon: "fa-signature",
    },
    {
      id: "understand-the-dashboard",
      title: "Understand the dashboard",
      videoSrc:
        "https://www.sourcewhale.app/training-videos/Login and Setup/Understanding the dashboard.mp4",
      modalButton: {
        title: "Dashboard",
        link: "/dashboard",
      },
      estimatedTime: 5,
      icon: "fa-grid-2",
      clickToComplete: true,
    },
  ],
  messages: [
    {
      id: "multi-step-campaign",
      title: "Create first multi-step campaign",
      videoSrc:
        "https://www.sourcewhale.app/training-videos/Campaigns/Creating a campaign.mp4",
      modalButton: {
        title: "Campaigns",
        link: "/campaigns",
      },
      estimatedTime: 5,
      icon: "fa-bullhorn",
    },
    {
      id: "navigate-campaign-settings",
      title: "Navigate the campaign settings",
      videoSrc:
        "https://www.sourcewhale.app/training-videos/Campaigns/Navigating the campaign settings.mp4",
      modalButton: {
        title: "Campaigns",
        link: "/campaigns",
      },
      estimatedTime: 5,
      icon: "fa-gear",
    },
    {
      id: "add-contacts-to-campaign-single-profile",
      title: "Add contacts to a campaign from LinkedIn (Single-Profile)",
      videoSrc:
        "https://www.sourcewhale.app/training-videos/Adding Contacts/Add a contact to a campaign from LinkedIn.mp4",
      estimatedTime: 2,
      icon: "fa-user",
    },
    {
      id: "add-contacts-to-campaign-multi-profile",
      title: "Add contacts to a campaign from LinkedIn (Multi-Profile)",
      videoSrc:
        "https://www.sourcewhale.app/training-videos/Adding Contacts/Add multiple contacts to a campaign from LinkedIn.mp4",
      estimatedTime: 2,
      icon: "fa-users",
    },
    {
      id: "upload-spreadsheet",
      title: "Uploading a spreadsheet to SourceWhale",
      videoSrc:
        "https://www.sourcewhale.app/training-videos/Spreadsheet Upload/Uploading a spreadsheet.mp4",
      modalButton: {
        title: "Spreadsheet Upload",
        link: "/upload",
      },
      estimatedTime: 5,
      icon: "fa-file-spreadsheet",
    },
  ],
  success: [
    {
      id: "best-practices-1",
      title:
        "Best Practices #1: Automated vs. Manual Steps & Multi-Channel Outreach",
      videoSrc:
        "https://www.sourcewhale.app/training-videos/Best Practices/1. Automated vs manual steps, and multi-channel outreach.mp4",
      estimatedTime: 2,
      icon: "fa-medal",
      clickToComplete: true,
    },
    {
      id: "best-practices-2",
      title: "Best Practices #2: Subject Lines, Variables, and Content Length",
      videoSrc:
        "https://www.sourcewhale.app/training-videos/Best Practices/2. Subject lines, variables, and content length.mp4",
      estimatedTime: 3,
      icon: "fa-medal",
      clickToComplete: true,
    },
    {
      id: "best-practices-3",
      title:
        "Best Practices #3: Finding Value, Breaking Down Information, & Call to Actions",
      videoSrc:
        "https://www.sourcewhale.app/training-videos/Best Practices/3. Finding value, breakdown information, and call to action.mp4",
      estimatedTime: 2,
      icon: "fa-medal",
      clickToComplete: true,
    },
    {
      id: "best-practices-4",
      title: "Best Practices #4: Follow-Ups & Custom Images",
      videoSrc:
        "https://www.sourcewhale.app/training-videos/Best Practices/4. Follow-ups and custom images.mp4",
      estimatedTime: 1,
      icon: "fa-medal",
      clickToComplete: true,
    },
    {
      id: "navigating-todos-page",
      title: "Navigating the To-Dos page",
      videoSrc:
        "https://www.sourcewhale.app/training-videos/To-Dos Page/Navigating the To-Dos page.mp4",
      modalButton: {
        title: "To-Dos",
        link: "/todo",
      },
      estimatedTime: 2,
      icon: "fa-check-square",
    },
    {
      id: "actioning-linkedin-requests",
      title: "Actioning LinkedIn Connection Requests",
      videoSrc:
        "https://www.sourcewhale.app/training-videos/To-Dos Page/Actioning LinkedIn Connection Requests.mp4",
      modalButton: {
        title: "To-Dos",
        link: "/todo",
      },
      estimatedTime: 2,
      icon: "fa-check-square",
    },
    {
      id: "visit-help-centre",
      title: "Visit help centre",
      url: "https://help.sourcewhale.app/en/",
      estimatedTime: 1,
      icon: "fa-square-info",
      clickToComplete: true,
    },
  ],
  liveTraining: [
    {
      id: "starter-workshop",
      title: "New Starter Workshop - Session 1",
      externalUrl:
        "https://sourcewhale.zoom.us/webinar/register/WN_aJvSkh0gRP2qbSvw6enDVQ",
      estimatedTime: 1,
      icon: "fa-medal",
      subText:
        "In this 45 minute session we will be covering the fundamentals of creating your first campaigns, showing you how to use industry insights to craft compelling content and utilise key SourceWhale features like Content Coach and WhaleGPT. Additionally you will learn how to use the SourceWhale extension to add contacts to your campaign so you're ready to go!",
    },
    {
      id: "acceleration-workshop",
      title: "Acceleration Workshop - Session 2",
      externalUrl:
        "https://sourcewhale.zoom.us/webinar/register/WN_NSmzUNj9QIC9KY0C5cTQuA",
      estimatedTime: 1,
      icon: "fa-medal",
      subText:
        "In this 45 minute Acceleration Session, we will explore industry-leading tips to maximize engagement with clients and candidates through effective outreach. We will also discuss the utilization of a multi-channel approach, which includes phone calls and LinkedIn campaigns. Please make sure to attend the New Starter Workshop before participating in this session.",
    },
  ],
};

export const capitalize = (word) =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const timestampToDuration = (timestamp) => {
  if (!(typeof timestamp !== "undefined" || timestamp)) return "00:00";

  const padZero = (num) => (num < 10 ? `0${num}` : num);

  const hours = padZero(Math.floor(timestamp / 3600));
  const mins = padZero(Math.floor((timestamp % 3600) / 60));
  const secs = padZero(Math.floor(timestamp % 60));

  return hours > 0 ? `${hours}:${mins}:${secs}` : `${mins}:${secs}`;
};

export const diallerVersion = () => {
  const user = getUser();
  return { plan: user.diallerPlan, regions: user.diallerRegions || [] };
};

export const userHasDialler = (checkUserKind = true) => {
  if (checkUserKind) {
    if (getUser().kind === "viewer") return false;
  }
  const version = diallerVersion();
  return ["standard", "unlimited"].includes(version.plan);
};

const addSpinnerHandlers = (opts, newOpts) => {
  if (opts.spinner) {
    addSpinner(opts.spinner);
    newOpts.complete = () => {
      removeSpinner(opts.spinner);
    };
  }
};

const handle400 = (text, opts) => {
  if (opts.userMustConfirmError) messageAlert(text);
  else {
    const { title = "An error occurred", type = "danger" } = opts.enrichToast
      ? opts.enrichToast(text) || {}
      : {};

    showToast({
      title,
      subtitle: text,
      type,
    });
  }
};

export const wrappedGet = (opts) => {
  const sentryError = new Error("wrappedGet");
  const newOpts = { ...opts };
  newOpts.error = (err, textStatus) => {
    if (err.status === 401) {
      localStorage.removeItem("provider");
      const { pathname } = window.location;
      const { search } = window.location;
      if (!pathname.includes("/signin") && !opts.url.includes("/internal-api"))
        window.location.replace(`/signin?redirect=${pathname}${search}`);
    } else {
      if ([400, 402].includes(err.status)) handle400(err.responseJSON, opts);
      if (opts.error) opts.error(err);
    }
    if (![0, 400, 401, 402, 502, 504].includes(err.status))
      console.error(opts.url, err.status);
    if (err.status === 502) {
      const error = new Error(`GET ${opts.url}: ${textStatus}`, {
        cause: sentryError,
      });
      error.response = err.responseText;
      error.status = err.status;
      Sentry.captureException(error);
    }
  };
  addSpinnerHandlers(opts, newOpts);
  $.get(newOpts);
};

export const wrappedPut = (opts) => {
  const sentryError = new Error("wrappedPut");
  const newOpts = { ...opts };
  newOpts.error = (err, textStatus) => {
    showToast({
      title: "Unable to proceed",
      subtitle: "Please try again or contact support if the issue persists",
      type: "danger",
    });
    if (opts.error) opts.error(err, textStatus);
    console.error(opts.url, err.status);
    if (err.status === 502 && sentryError) {
      const error = new Error(`PUT ${opts.url}: ${textStatus}`, {
        cause: sentryError,
      });
      error.response = err.responseText;
      error.status = err.status;
      Sentry.captureException(error);
    }
  };
  newOpts.contentType = "";
  newOpts.type = "PUT";

  addSpinnerHandlers(opts, newOpts);
  $.ajax(newOpts);
};

const wrappedPostInner = (opts, sentryError) => {
  const newOpts = { ...opts };

  if (newOpts.contentType === undefined)
    newOpts.contentType = "application/json";

  newOpts.error = (err, textStatus) => {
    if ([400, 402].includes(err.status)) {
      handle400(err.responseJSON || err.responseText, opts);
    } else if (err.status !== 401) {
      showToast({
        title: "Unable to proceed",
        subtitle: "Please try again or contact support if the issue persists",
        type: "danger",
      });
    }
    if (opts.error) opts.error(err);
    if (![0, 400, 401, 402, 502, 504].includes(err.status)) {
      console.error(opts.url, err.status);
    }
    if (err.status === 502 && sentryError) {
      const error = new Error(`POST ${opts.url}: ${textStatus}`, {
        cause: sentryError,
      });
      error.response = err.responseText;
      error.status = err.status;
      Sentry.captureException(error);
    }
  };
  addSpinnerHandlers(opts, newOpts);
  $.post(newOpts);
};

export const largePOST = (opts, sentryError = null) => {
  sentryError = sentryError || new Error("largePOST");
  wrappedGet({
    url: "/api/requests/get_upload_url",
    success: (res) => {
      const url = `/upload/${res.url.split("/upload/")[1]}`;
      wrappedPut({
        url,
        data: opts.data,
        success: () => {
          const newOpts = { ...opts };
          newOpts.data = JSON.stringify({ url, endpoint: opts.url });
          newOpts.url = "/api/requests/call_endpoint";
          wrappedPostInner(newOpts, sentryError);
        },
        error: opts.error,
      });
    },
    error: opts.error,
  });
};

export const wrappedPost = (opts) => {
  const sentryError = new Error("wrappedPOST");
  if (opts.data && opts.data.length > 6000000) {
    if (opts.url.startsWith("/")) {
      largePOST(opts, sentryError);
      return;
    }
  }
  const newOpts = { ...opts };
  newOpts.error = (err) => {
    // API Gateway sometimes returns 500 when request is too large
    if ([413, 500].includes(err.status)) {
      if (opts.url.startsWith("/")) {
        largePOST(opts, sentryError);
        return;
      }
    }
    if (opts.error) opts.error(err);
  };
  wrappedPostInner(newOpts, sentryError);
};

export const sendToHeap = (event, properties) => {
  if (window.heap && event) {
    if (properties) window.heap.track(event, properties);
    else window.heap.track(event);
  }
};

const timestampToString = (timestamp) => {
  if (!(typeof timestamp !== "undefined" || timestamp)) return "0:00";

  const mins = Math.floor(timestamp / 60);
  let secs = Math.floor(timestamp - mins * 60);
  if (secs < 10) {
    secs = `0${secs.toString()}`;
  }
  return `${mins}:${secs}`;
};

export const displaySecondsSince = (startedAt, id) => {
  const now = parseInt(Date.now() / 1000, 10);
  const duration = timestampToString(now - (startedAt || now));
  $(`#${id}`).text(duration);
};

export const clearSecondsSince = (id) => {
  $(`#${id}`).text("");
};

export const initializeGeneralUtils = () => {
  $(".alert").on("close.bs.alert", (e) => {
    e.preventDefault();
    $(".alert").hide();
  });

  $(".datepicker").each((_, e) => {
    if ($(e).hasClass("multi") || $(e).hasClass("time")) return;
    if (!$(e).find("input").length) return;
    if ($(e).hasClass("internal")) return;
    $(e)
      .find("input")
      .daterangepicker({
        autoApply: true,
        singleDatePicker: true,
        startDate: new Date(),
        minDate: $(e).hasClass("any-date") ? "" : new Date(),
        opens: $(e).hasClass("left") ? "left" : "right",
        locale: { format: "DD MMM YYYY" },
      });
  });

  $(".datepicker.time").each((_, e) => {
    $(e)
      .find("input")
      .daterangepicker({
        parentEl: `#${$(e).data("parent")}`,
        autoApply: true,
        singleDatePicker: true,
        startDate: moment().add(10, "minutes"),
        minDate: $(e).hasClass("any-date") ? "" : new Date(),
        opens: $(e).hasClass("left") ? "left" : "right",
        drops: $(e).hasClass("up") ? "up" : "down",
        locale: { format: "YYYY-MM-DDTHH:mm" },
        timePicker: true,
        timePicker24Hour: true,
      });
  });

  $(".datepicker.multi").each((_, e) => {
    if ($(e).hasClass("internal")) return;
    const { teamCreatedAt } = getUser();
    const direction = $(e).hasClass("left") ? "left" : "right";
    const drops = $(e).hasClass("up") ? "up" : "down";
    let startDate;
    if ($(e).hasClass("sw-all-time")) {
      startDate = new Date("07/01/2021");
    } else if ($(e).hasClass("all-time")) {
      startDate = new Date("01/01/2020");
    } else {
      startDate = moment().subtract(29, "days");
    }

    $(e)
      .find("input")
      .daterangepicker(
        {
          autoApply: true,
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "Last 90 Days": [moment().subtract(89, "days"), moment()],
            "This Week": [moment().startOf("week"), moment().endOf("week")],
            "Last Week": [
              moment().subtract(1, "week").startOf("week"),
              moment().subtract(1, "week").endOf("week"),
            ],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
            "This Quarter": [
              moment().quarter(moment().quarter()).startOf("quarter"),
              moment().quarter(moment().quarter()).endOf("quarter"),
            ],
            "Last Quarter": [
              moment()
                .quarter(moment().quarter())
                .subtract(1, "Q")
                .startOf("quarter"),
              moment()
                .quarter(moment().quarter())
                .subtract(1, "Q")
                .endOf("quarter"),
            ],
            "This Year": [moment().startOf("year"), moment()],
            "Last Year": [
              moment().subtract(1, "years").startOf("year"),
              moment().subtract(1, "years").endOf("year"),
            ],
            "All Time": [
              $(e).hasClass("all-time")
                ? new Date("01/01/2020")
                : new Date("07/01/2021"),
              moment(),
            ],
          },
          showCustomRangeLabel: false,
          alwaysShowCalendars: true,
          linkedCalendars: false,
          startDate,
          endDate: new Date(),
          minDate: teamCreatedAt
            ? moment.unix(teamCreatedAt).format("DD MMM YYYY")
            : new Date("01/01/2020"),
          maxDate: new Date(),
          opens: direction,
          drops,
          locale: {
            format: "DD MMM YYYY",
          },
        },
        () => {
          setDateRangePickerChanged(true);
        },
      );
  });

  $(document).on(
    "click",
    ".dropdown-menu .dropdown-item.contains-submenu",
    (e) => {
      e.stopImmediatePropagation();
      $(e.currentTarget).find(".dropdown-menu.submenu").toggle();
      $(".dropdown").on("hide.bs.dropdown", () =>
        $(e.currentTarget).find(".submenu").hide(),
      );
    },
  );

  $("#backBtn").click(() => {
    window.history.back();
  });

  $(".disabled-allow-pointer-events").on("click", (e) => {
    e.preventDefault();
  });

  $(".alphaonly").on(
    "keydown",
    (event) =>
      event.key.match(/^[a-zA-Z\s]+$/) ||
      event.keyCode === 8 ||
      event.keyCode === 9 ||
      event.keyCode === 37 ||
      event.keyCode === 39,
  );
};
